@import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    font-family: 'Italiana', serif;


}

*::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.open-sans {
    font-family: 'Open Sans', sans-serif;
}

.text-10xl {
    font-size: 10rem;
    line-height: 1;
}

.middle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -10%);
}

.max-w-50 {
    max-width: 50%;
}

.min-h-50 {
    height: 50vh
}

.list {
    list-style-type: "→ ";
    padding-left: 1em;
    padding: 1em;
}

.link-underline {
    border-bottom-width: 0;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
    background-size: 0 3px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .4s ease-in-out;
}

.link-underline-black {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#F59E0B, #F59E0B)
}

.link-underline:hover {
    background-size: 100% 3px;
    background-position: 0 100%
}

/* set navbar z-index */
.navbar {
    z-index: 10000;
}

.topBtn {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100000;
}

.bg-dark-blue {
    background-color: #0B0B3B;
}

.fade-out {
    animation: disappear 5s ease-out;
    animation-delay: 4s;
}

@keyframes disappear {
    0% {
        transform: translateY(100%);
        opacity: 1;
    }

    100% {
        transform: translateY(0%);
        opacity: 0;
    }
}

.test-animate:hover {
    /* background-color: black; */
    background-color: linear-gradient(to bottom, white, black, white);
    color: #ffedd5;
    -webkit-transition: background-color 1.2s;
    /* For Safari */
    transition: background-color 1.2s;

    /* For modern browsers */

}
