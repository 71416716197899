
#face{
    stroke-dasharray: 4250;
    stroke-dashoffset: 0;
    animation: drawLongest 5s linear;
}

#left-arm{
    stroke-dasharray: 750;
    stroke-dashoffset: 0;
    animation: draw 5s linear;
    
}

#right-arm{
    stroke-dasharray: 750;
    stroke-dashoffset: 0;
    animation: draw 5s linear;
}

#right-ear{
    stroke-dasharray: 750;
    stroke-dashoffset: 0;
    animation: draw 5s linear;
}

#left-ear{
    stroke-dasharray: 750;
    stroke-dashoffset: 0;
    animation: draw 5s linear;
}

#right-eye{
    stroke-dasharray: 750;
    stroke-dashoffset: 0;
    animation: draw 5s linear;
    /* animation-delay: 6s; */
}

#left-eye{
    stroke-dasharray: 750;
    stroke-dashoffset: 0;
    animation: draw 5s linear;
    /* animation-delay: 6s; */
}

@keyframes draw {
    from {
        stroke-dashoffset: 1000;
    }
    to{
        stroke-dashoffset: 0;
    }

}
@keyframes drawLongest {
    from {
        stroke-dashoffset: 4250;

    }
    to{
        stroke-dashoffset: 0;
    }
}

.swoop-in{
    animation: swoop-in 3.5s ease-in-out;
}

@keyframes swoop-in {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}